<template>
  <div>
    <v-row>
    <v-col v-for="(apiData, i) in [apiBaseData, apiTargetData]"  :key="i" cols="6">
      <v-card style="background-color: white; padding: 20px">
        <table style="">
          <tr>
            <th
              style="background-color: #3c4a64; color: white; padding: 10px"
            >
              date
            </th>
            <th
              style=" background-color: #3c4a64; color: white; padding: 10px"
            >
              content
            </th>
          </tr>
          <tr v-for="(item, n) in apiData" :key="`${n}${i}`">
            <td>{{ item.createdDate }}</td>
            <td v-html="item.content"></td>
          </tr>
        </table> </v-card
    ></v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { dataSetCodeConverter } from "../../../mixins/dataSetCodeConverter";

export default {
  name: "ComparisonTable",
  mixins: [dataSetCodeConverter],
  data() {
    return {
      dataSet: this.$route.query.dataSet,
      period: this.$route.query.period,
      cateKo: this.$route.query.cateKo,
      word: this.$route.query.word,

      apiBaseData: null,
      apiTargetData: null
    };
  },
  async created() {
    const requestUrlBase = `https://${process.env.VUE_APP_API_BASE_URL}:${
      process.env.VUE_APP_API_BASE_PORT
    }/api/searchV4?dataSet=${this.krToEn(this.dataSet)}&terms=${
      this.cateKo
    }${encodeURIComponent("#")}${this.word}&targetNo=${
      this.period
    }&targetType=BASE`;

    const requestUrlTarget = `https://${process.env.VUE_APP_API_BASE_URL}:${
      process.env.VUE_APP_API_BASE_PORT
    }/api/searchV4?dataSet=${this.krToEn(this.dataSet)}&terms=${
      this.cateKo
    }${encodeURIComponent("#")}${this.word}&targetNo=${
      this.period
    }&targetType=TARGET`;

    //  BASE
    await axios
      .get(requestUrlBase, {
        headers: { Authorization: this.$store.getters.getLoginToken }
      })
      .then(res => (this.apiBaseData = res.data));

    // TARGET
    await axios
      .get(requestUrlTarget, {
        headers: { Authorization: this.$store.getters.getLoginToken }
      })
      .then(res => (this.apiTargetData = res.data));
  },
  mounted() {},
  methods: {
    getRandomIntInclusive: function(max) {
      const min = Math.ceil(0);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min; //최댓값도 포함, 최솟값도 포함
    },
    getRandomBackgroundColor: function() {
      const colors = [
        "#F6EBE7",
        "#D3D3D6",
        "#E8D4D4",
        "#E7E7E8",
        "#B5C3C8",
        "#F1F2F5",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF"
      ];
      return colors[this.getRandomIntInclusive(colors.length)];
    }
  }
};
</script>

<style scoped>
.view {
  margin: auto;
}

.wrapper {
  position: relative;
  overflow: auto;
  height: 580px;
}

.sticky-col {
  position: sticky;
  position: -webkit-sticky;
  background-color: white;
  top: -1px;
}

.first-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
}

.second-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 100px;
}

table th,
td {
  border: 1px solid darkgrey;
  text-align: center;
}
</style>
