<template>
  <v-container style="max-width: none; margin:0">
          <ComparisonTable />
  </v-container>
</template>

<script>
import ComparisonTable from "../../../components/explore_period/third_page/ComparisonTable";
export default {
  name: "ComparisonTablePage",
  components: { ComparisonTable },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
